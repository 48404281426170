var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"position",staticClass:"position"},[_c('div',{staticClass:"bg",style:({
    '--width': _vm.width
  })},[_c('div',{ref:"draw",staticClass:"draw"},[_c('div',{staticClass:"draw-content"},_vm._l((_vm.showList),function(item){return _c('div',{key:item.satellite_type,staticClass:"flex item",class:[item.type],style:({
            bottom: item.pos.y + 'px',
            left: item.pos.x + 'px',
          }),attrs:{"data-value":item.id}})}),0)])]),_c('div',{staticClass:"flex checkBox"},[_c('el-checkbox-group',{attrs:{"text-color":"#333333"},model:{value:(_vm.checkList),callback:function ($$v) {_vm.checkList=$$v},expression:"checkList"}},_vm._l((_vm.satellites),function(item){return _c('el-checkbox',{key:item.value,style:({
        '--color': _vm.dict[item.value]
      }),attrs:{"label":item.value}})}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }